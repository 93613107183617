<template>
  <!-- Table Container Card -->
  <b-card no-body class="mb-0">
    <div class="m-2">
      <b-row class="mb-1">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h5>Clientes asociados</h5>
        </b-col>

        <!-- New customer -->
        <b-col cols="6" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-dropdown
              toggle-class="text-decoration-none"
              variant="success"
              dropleft
              no-caret
              size="sm"
            >
              <template #button-content>
                <span>
                  <feather-icon icon="PlusIcon" size="18" />
                </span>
              </template>
              <b-dropdown-item
                :to="{
                  name: 'auth-register-establishment-client',
                  params: { id: $route.params.id },
                }"
              >
                Agregar cliente
              </b-dropdown-item>
              <b-dropdown-item @click="$bvModal.show('import-customer-modal')">
                Subir Excel/CSV
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>

      <!-- Description -->
      <b-row v-if="!isMobile" class="mt-1 mb-1">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h6>
            Aquí podrás ver todos los usuarios de CompiTienda que son tus
            clientes. Además, podrás ver sus saldos (débito y crédito) y
            modificar el límite de crédito que otorgas a cada uno de ellos
          </h6>
        </b-col>
      </b-row>

      <b-row v-if="isMobile" class="mt-1 mb-1">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <span>Toca el nombre del cliente para ver detalles</span>
        </b-col>
      </b-row>

      <!-- Searchbar -->
      <b-row clas="mt-3">
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="userName"
              placeholder="Buscar nombre"
              class="search-user"
            />
            <b-input-group-append v-if="userName">
              <b-button
                variant="outline-warning"
                @click="clearSearchbarAndResetSearch()"
              >
                Borrar
              </b-button>
            </b-input-group-append>
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <overlay :show="loading">
      <b-table
        v-if="!isMobile"
        ref="refInvoiceListTable"
        :items="establishmentCustomers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron clientes"
        class="text-center"
        selectable
        select-mode="single"
        @row-selected="openCustomerModal"
      >
        <!-- Column: Customer -->
        <template #cell(name)="data">
          <b-media
            :id="`invoice-row-${data.item.id}-preview-address`"
            vertical-align="center"
          >
            <template #aside>
              <b-avatar size="32" :src="data.item.logo" />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </span>
            <small class="text-muted">{{ data.item.email }}</small>
            <div class="text-muted">
              <small v-if="data.item.phone_number">{{
                data.item.phone_number.slice(2)
              }}</small>
            </div>
          </b-media>
        </template>

        <!-- Column: Saldo a favor -->
        <template #cell(balance)="data">
          <div v-if="data.item.netBalance && data.item.netBalance > 0">
            <b-badge pill :variant="'light-success'">
              {{ data.item.netBalance.toFixed(2) }} MXN
            </b-badge>
          </div>
        </template>

        <!-- Column: Detalles de accountingg -->
        <template #cell(show_details)="row">
          <b-button
            size="sm"
            :variant="'primary'"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? "Ocultar" : "Ver" }} detalles
          </b-button>
        </template>

        <template #row-details="data">
          <b-card>
            <b-row class="mb-2" v-if="data.item.accountings && data.item.accountings.length > 0">
              <b-table
                :items="data.item.accountings"
                :fields="tableColumnsAccountingsDetails"
              >
                <!-- Column: Tipo de cuenta -->
                <template #cell(account_type)="accounting">
                  <b-badge pill :variant="'light-info'">
                    {{ accounting.value | accountType }}
                  </b-badge>
                </template>

                <!-- Column: Saldo a favor -->
                <template #cell(balance)="accounting">
                  <b-badge pill variant="light-success">
                    {{ accounting.value }}
                  </b-badge>
                </template>

                <!-- Column: Saldo deudor -->
                <template #cell(loan_balance)="accounting">
                  <b-badge pill variant="light-danger">
                    {{ accounting.value }}
                  </b-badge>
                </template>

                <!-- Column: Loan Limit -->
                <template #cell(loan_limit)="accounting">
                  <b-badge pill variant="light-warning">
                    {{ accounting.value }}
                  </b-badge>

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="data.item.active_status === true"
                      @click="setNewLoanLimit(accounting.item.id)"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50"
                        >Modificar límite de crédito</span
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <template #cell(accounting_payment)>
                  <b-button v-b-modal.select-store-modal :variant="'primary'">
                    Abonar
                  </b-button>
                </template>
              </b-table>
            </b-row>
            <b-row v-else>
              <b-col>
                <p class="text-center">No hay detalles de accounting</p>
              </b-col>
            </b-row>
          </b-card>
        </template>

        <!-- Column: Compras -->
        <template #cell(orders)="data">
          <b-button
            size="sm"
            :variant="'primary'"
            @click="getCustomerOrders(data.item.customer_id, data.item.name)"
          >
            Ver compras
          </b-button>
          <b-modal
            :id="`view-orders-modal-${data.item.customer_id}`"
            title="Compras de cliente"
            class="modal-content"
            cancel-variant="outline-danger"
            ok-only
            size="lg"
            @ok="$bvModal.hide(`view-orders-modal-${data.item.customer_id}`)"
          >
            <SalesTransactionsTableByCustomer
              :customer="customer"
              :name="name"
            />
          </b-modal>
        </template>

        <!-- Column: Referido Por -->
        <template #cell(referring_users)="data">
          <b-badge
            pill
            :variant="'light-primary'"
            v-for="user in data.item.referring_users"
          >
            {{ user.name }}
          </b-badge>
        </template>
      </b-table>

      <!--Customer table for Mobiles -->
      <ul v-else class="customer-list" style="list-style-type: none">
        <li
          v-for="item in establishmentCustomers"
          :key="item.id"
          class="mb-1"
          @click="openCustomerModal(item)"
        >
          <b-media vertical-align="center" class="align-items-center">
            <template #aside>
              <b-avatar
                size="32"
                :src="item.logo"
                :text="avatarText(item.name)"
                :variant="`light-primary`"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ item.name }}
            </b-link>
            <div>
              <small class="text-muted">{{ item.email }}</small>
            </div>
            <div>
              <small v-if="item.phone_number" class="text-muted">{{
                item.phone_number.slice(2)
              }}</small>
            </div>
          </b-media>
        </li>
      </ul>

      <div v-if="pagination" class="m-2">
        <pagination
          :loading="loading"
          :handlePagination="handlePagination"
          :pagination="pagination"
          :entriesPerPage.sync="entriesPerPage"
        />
      </div>
    </overlay>

    <!-- Modal: Customer details -->
    <b-modal
      ref="customerDetailsModal"
      title="Detalles de la fila seleccionada"
      ok-only
    >
      <template>
        <div class="customer-user">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="64"
                :src="selectedCustomer.logo"
                :text="avatarText(selectedCustomer.name)"
                :variant="`light-primary`"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ selectedCustomer.name }}
            </b-link>
            <div>
              <small v-if="selectedCustomer.email" class="text-muted">{{
                selectedCustomer.email
              }}</small>
            </div>
            <div>
              <small v-if="selectedCustomer.phone_number" class="text-muted">{{
                selectedCustomer.phone_number.slice(2)
              }}</small>
            </div>
            <div
              v-if="selectedCustomer.address && selectedCustomer.address.street"
            >
              <small class="text-muted">
                {{ selectedCustomer.address.street }}
                {{ selectedCustomer.address.ext_number }},
                {{ selectedCustomer.address.postal_code }},
                {{ selectedCustomer.address.city }},
                {{ selectedCustomer.address.state }},
                {{ selectedCustomer.address.country }}
              </small>
            </div>
          </b-media>

          <!-- Accountings: details -->
          <b-table
            :items="selectedCustomer.accountings"
            responsive
            :fields="accountingsDetailsShort"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="mt-2 text-center"
            small
          >
            <!-- Column: Tipo de cuenta -->
            <template #cell(account_type)="data">
              <b-badge pill :variant="'light-success'">
                {{ data.item.account_type | accountType }}
              </b-badge>
            </template>

            <!-- Column: Balance-->
            <template #cell(balance)="data">
              <b-badge pill :variant="'light-success'">
                {{ data.item.balance }}
              </b-badge>
            </template>

            <!-- Column: Saldo deudor -->
            <template #cell(loan_balance)="data">
              <b-badge pill :variant="'light-danger'">
                {{ data.item.loan_balance }}
              </b-badge>
            </template>

            <!-- Column: Loan Limit -->
            <template #cell(loan_limit)="data">
              <b-badge pill :variant="'light-warning'">
                {{ data.item.loan_limit }}
              </b-badge>
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="setNewLoanLimit(data.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50"
                    >Modificar límite de crédito</span
                  >
                </b-dropdown-item>

                <b-dropdown-item @click="openSelectStoreModal()">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Abonar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <b-button
            size="sm"
            :variant="'primary'"
            @click="
              getCustomerOrders(
                selectedCustomer.customer_id,
                selectedCustomer.name
              )
            "
          >
            Ver compras
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Modal: New Loan Limit -->
    <b-modal id="NewLoanLimitModal">
      <template #modal-title>
        <div class="d-flex align-items-center">
          <feather-icon icon="EditIcon" />
          <span class="ml-1">Modificar límite de crédito</span>
        </div>
      </template>

      <validation-observer ref="amountLimitLoanForm" tag="form">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">¿Cuál es el nuevo límite de crédito?</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="v-ammount">
              <validation-provider
                #default="{ errors }"
                name="Cantidad máxima de crédito"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      :state="errors.length > 0 ? false : null"
                    >
                      <strong :class="errors.length > 0 ? 'text-danger' : null"
                        >$</strong
                      >
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="v-ammount"
                    v-model="newLoanLimit"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nuevo límite de crédito"
                    size="lg"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="updateLoanLimit()">
          Actualizar
        </b-button>
      </template>
    </b-modal>

    <!-- Modal: NFC modal -->
    <b-modal
      id="nfc-loan-modal"
      ok-only
      ok-variant="success"
      ok-title="Cerrar"
      @ok="$bvModal.hide('nfc-loan-modal')"
      @hidden="SET_LOAN_PAYMENT(false)"
    >
      <b-row class="mb-2" v-if="!enc && !walleatName">
        <b-col class="text-center">
          <h1 class="">Escanear Walleat</h1>
          <p>acerca el Walleat a tu dispositivo con NFC</p>
          <div>
            <b-img
              src="https://thumbs.gfycat.com/LawfulWavyIlsamochadegu-small.gif"
              width="200"
              rounded
            />
          </div>
        </b-col>
      </b-row>
      <NFC />
    </b-modal>

    <!-- Modal: Select store modal -->
    <b-modal
      id="select-store-modal"
      ok-only
      ok-variant="success"
      ok-title="Continuar"
      @ok="showNfcModal()"
    >
      <b-col md="6">
        <b-form-group
          label="Seleccionar tienda en la que compra el cliente"
          label-for="last-name"
        >
          <validation-provider
            #default="{ errors }"
            name="Tienda"
            rules="required"
          >
            <v-select
              v-model="selectedStore"
              :options="currentEstablishment.stores_attributes"
              :reduce="(val) => val.id"
              label="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-modal>

    <!-- Modal: Orders mobile modal -->
    <b-modal
      id="view-orders-modal-mobile"
      title="Compras de cliente"
      class="modal-content"
      ok-only
      size="sm"
    >
      <SalesTransactionsTableByCustomer :customer="customer" :name="name" />
    </b-modal>

    <import-customer-modal />
  </b-card>
</template>

<script>
import vSelect from "vue-select"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import Pagination from "@/@core/components/Pagination.vue"
import ImportCustomerModal from "./import-customer-modal.vue"
import NFC from "@/views/e-commerce/e-commerce-checkout/NfcLoanPaymentService.vue"
import SalesTransactionsTableByCustomer from "../sales/SalesTransactionsTableByCustomer.vue"
import Overlay from "@/@core/components/Overlay.vue"

import messagesMixin from "@core/mixins/messagesMixin"
import { avatarText } from "@core/utils/filter"
import { handleAPIErrors } from "@/utils/fetch-utils"

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,

    Overlay,
    NFC,
    Pagination,
    ImportCustomerModal,
    SalesTransactionsTableByCustomer,
  },
  mixins: [messagesMixin],
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    return {
      userName: null,
      selectedStore: null,
      customer: null,
      name: null,
      walleatName: null,
      ordersShown: null,
      loading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      entriesPerPage: "10",
      accountings: null,
      accounting: {
        loan_limit: null,
      },
      newLoanLimit: null,
      accountingId: null,
      screenWidth: window.innerWidth,
      selectedCustomer: {
        name: null,
        email: null,
        phone_number: null,
        referring_users: null,
        accountings: null,
        address: { street: "" },
      },

      tableColumnsAccountingsDetails: [
        {
          key: "account_type",
          label: "Tipo de cuenta",
        },
        {
          key: "balance",
          label: "Saldo a favor",
        },
        {
          key: "loan_balance",
          label: "Saldo deudor",
        },
        {
          key: "loan_limit",
          label: "Límite de crédito",
        },
        {
          key: "accounting_payment",
          label: "Pagar",
        },
      ],
      accountingsDetailsShort: [
        {
          key: "account_type",
          label: "",
        },
        {
          key: "balance",
          label: "Favor",
        },
        {
          key: "loan_balance",
          label: "Deudor",
        },
        {
          key: "loan_limit",
          label: "Límite de crédito",
        },
      ],
      perPage: ["10", "20", "50", "100"],
      perPageForCustomerOrders: ["10", "20", "50", "100"],
    }
  },
  computed: {
    ...mapGetters("users", ["establishmentCustomers", "pagination"]),
    ...mapGetters("establishments", ["currentEstablishment"]),
    ...mapGetters("nfcService", ["enc"]),

    isMobile() {
      return this.screenWidth < 770
    },

    tableColumns() {
      if (this.screenWidth < 770) {
        return [
          {
            key: "name",
            label: "Cliente",
          },
        ]
      } else {
        return [
          {
            key: "name",
            label: "Cliente",
          },
          {
            key: "balance",
            label: "Saldo a favor",
          },
          {
            key: "show_details",
            label: "Detalles de accountingg",
          },
          {
            key: "orders",
            label: "Compras",
          },
          {
            key: "referring_users",
            label: "Referido Por",
          },
        ]
      }
    },
  },
  watch: {
    userName: _.debounce(function () {
      this.getEstablishmentCustomers({ page: 1 })
    }, 500),

    entriesPerPage() {
      this.getEstablishmentCustomers({ page: 1 })
    },
  },

  beforeMount() {
    this.getEstablishmentCustomers()
  },

  mounted() {
    window.addEventListener("resize", this.handleResize)
    this.startNfcService()
    this.ordersShown = this.orders
    this.onlineOrdersConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`
    )
    sessionStorage.setItem(
      "wsConnectionOnlineOrders",
      JSON.stringify(this.onlineOrdersConnection)
    )

    this.onlineOrdersConnection.onmessage = (event) => {
      const messagex = JSON.parse(event.data)
      if (typeof messagex.message === "object") {
        this.$swal({
          title: messagex.message.title,
          text: messagex.message.content_message,
          icon: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        })
        this.fetchOrders({
          by_order_type: "sell",
          by_order_mode: "online",
          by_store: this.$route.params.id,
        })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.ordersShown = this.orders
          })
      }
    }

    this.onlineOrdersConnection.onopen = () => {
      // eslint-disable-next-line
      console.log("Successfully connected to the echo websocket server...")

      // eslint-disable-next-line
      this.onlineOrdersConnection.send(
        JSON.stringify({
          command: "subscribe",
          identifier: '{"channel":"ApplicationCable::MyChannel"}',
        })
      )
    }
  },
  destroyed() {
    this.onlineOrdersConnection.close()
  },
  methods: {
    ...mapActions("orders", ["fetchOrders"]),
    ...mapActions("users", ["fetchEstablishmentCustomers"]),
    ...mapActions("nfcService", ["startNfcService"]),
    ...mapMutations("pos", ["SET_LOAN_PAYMENT"]),
    ...mapMutations("stores", ["setStoreIdForLoanPayment"]),
    ...mapActions("users", ["updateAccountingLoanLimit"]),

    openSelectStoreModal() {
      this.$bvModal.show("select-store-modal")
    },

    openCustomerModal(item) {
      this.selectedCustomer = item
      if (this.screenWidth < 770) {
        this.$refs.customerDetailsModal.show()
      }
    },

    handleResize() {
      this.screenWidth = window.innerWidth
    },

    setNewLoanLimit(accountingId) {
      this.accountingId = accountingId
      this.$bvModal.show("NewLoanLimitModal")
    },

    updateLoanLimit() {
      this.accounting.loan_limit = Number(this.newLoanLimit)
      this.updateAccountingLoanLimit({
        id: this.accountingId,
        accounting: this.accounting,
      })
        .then((response) => {
          this.$swal({
            title: "Éxito!",
            text: "Límite de crédito actualizado",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
          this.getEstablishmentCustomers({ page: 1 })
          this.newLoanLimit = null
          this.$bvModal.hide("NewLoanLimitModal")
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data, error?.message)

          this.toastError({
            title: "Error",
            text: errors.join("\n"),
          })

          this.$bvModal.hide("NewLoanLimitModal")
        })
    },

    showNfcModal() {
      this.setStoreIdForLoanPayment(this.selectedStore)
      this.$bvModal.hide("select-store-modal")
      this.$bvModal.show("nfc-loan-modal")
    },

    getCustomerOrders(customerId, name) {
      this.loading = true
      this.customer = customerId
      this.name = name
      this.fetchOrders({
        by_customer: customerId,
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPageForCustomerOrders,
          },
        },
      })
        .then(() => {
          if (this.isMobile) {
            this.$bvModal.show("view-orders-modal-mobile")
          } else {
            this.$bvModal.show(`view-orders-modal-${customerId}`)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    clearSearchbarAndResetSearch() {
      this.userName = ""
    },

    getEstablishmentCustomers({ page } = {}) {
      this.loading = true
      return this.fetchEstablishmentCustomers({
        by_name: this.userName,
        by_active_status: true,
        by_establishment: this.$route.params.id,
        meta: {
          pagination: {
            page: page || this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then((res) => {})
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data, error?.message)

          this.toastError({
            title: "Error",
            text: errors.join("\n"),
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    handlePagination({ page }) {
      this.getEstablishmentCustomers({ page })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.customer-list {
  padding: 0 20px;
}

.search-input {
  max-width: 200px;
}

.customer-user {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  ul {
    width: 100%;
    max-height: 200px;
  }

  li {
    margin: 5px 0;
  }
}
</style>
