<template>
  <b-modal
    :id="id"
    title="Import Customers"
    @hide="reset"
    ok-title="Cancelar"
    ok-variant="outline-secondary"
    ok-only
    scrollable
  >
    <h2 class="text-lg font-bold mb-50">Formato de archivo</h2>
    <p>Asegúrate de que el archivo contenga las columnas:</p>
    <ul class="mb-2">
      <li><strong>Nombre:</strong> "nombre", "name", "nombres"</li>
      <li><strong>Email:</strong> "email", "correo", "e-mail", "mail"</li>
      <li>
        <strong>Teléfono:</strong> "telefono", "teléfono", "phone", "celular"
      </li>
    </ul>
    <b-form @submit.prevent="submit" class="my-1">
      <b-form-file
        class="mb-1"
        size="lg"
        v-model="file"
        :state="Boolean(file)"
        placeholder="Seleciona un archivo Excel/CSV"
        drop-placeholder="Suelta el archivo aquí"
        accept=".xls,.xlsx,.csv"
        required
        @change="handleFileUpload"
      ></b-form-file>
      <div class="d-flex justify-content-end">
        <b-button type="submit" variant="primary"> Importar </b-button>
      </div>
    </b-form>

    <b-table
      v-if="parsedData.length"
      :items="parsedData"
      :fields="[
        { key: 'name', label: 'Nombre' },
        { key: 'email', label: 'Email' },
        { key: 'phone_number', label: 'Teléfono' },
      ]"
    ></b-table>
  </b-modal>
</template>

<script>
import Papa from "papaparse"
import * as XLSX from "xlsx"
import { mapActions } from "vuex"

import messagesMixin from "@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/@core/utils/fetch-utils"

export default {
  props: {
    id: {
      type: String,
      default: "import-customer-modal",
    },
  },
  mixins: [messagesMixin],
  data() {
    return {
      file: null,
      parsedData: [],
      columnMappings: {
        nombre: ["nombre", "name", "nombres"],
        email: ["email", "correo", "e-mail", "mail"],
        telefono: ["telefono", "teléfono", "phone", "celular"],
      },
    }
  },
  methods: {
    ...mapActions("users", [
      "fetchEstablishmentCustomers",
      "registerBulkEstablishmentCustomer",
    ]),

    mapColumns(data) {
      return data.map((row) => {
        return {
          name: this.findColumn(row, this.columnMappings.nombre),
          email: this.findColumn(row, this.columnMappings.email),
          phone_number: this.findColumn(row, this.columnMappings.telefono),
        }
      })
    },
    parseCSV(file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          this.parsedData = this.mapColumns(results.data)
        },
      })
    },

    parseExcel(data) {
      if (!data) return

      const workbook = XLSX.read(data, { type: "array" })

      const sheetName = workbook.SheetNames[0]
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
      this.parsedData = this.mapColumns(sheet)
    },

    findColumn(row, possibleNames) {
      for (const name of possibleNames) {
        if (row[name]) return row[name]
      }
      return ""
    },

    handleFileUpload(event) {
      const file = event.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.readAsArrayBuffer(file)

      reader.onload = async (e) => {
        const dataExcel = new Uint8Array(e.target.result)

        if (file.name.endsWith(".csv")) {
          this.parseCSV(file)
        } else if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx")) {
          this.parseExcel(dataExcel)
        }
      }
    },

    reset() {
      this.file = null
      this.parsedData = []
    },

    validateParsedData() {
      return this.parsedData.every(
        (row) =>
          row.nombre &&
          row.email &&
          row.telefono &&
          row.email.includes("@") &&
          row.telefono.length >= 10
      )
    },

    async submit() {
      if (this.validateParsedData()) {
        this.toastError({
          title: "Error",
          text: "Por favor, verifica que los datos sean correctos",
        })
        return
      }

      this.loadingSwal({
        title: "Importando clientes",
        text: "Por favor, espera un momento",
      })

      try {
        await this.registerBulkEstablishmentCustomer({
          establishment_id: this.$route.params.id,
          users: this.parsedData.filter(
            (u) => u.name !== "" && u.email !== "" && u.phone_number !== ""
          ),
        })
        await this.fetchEstablishmentCustomers({ by_active_status: true, by_establishment: this.$route.params.id })

        this.toastSuccess({
          title: "Clientes importados",
          text: "Los clientes han sido importados correctamente",
        })
        this.$bvModal.hide(this.id)
        this.reset()
      } catch (e) {
        const errors = handleAPIErrors(e.response.data)

        this.toastError({
          title: "Error",
          text: errors.join("\n"),
        })
      } finally {
        this.closeSwal()
      }
    },
  },
}
</script>
